import { Observable, Subject, from, throwError } from 'rxjs';
import { map, catchError, tap, switchMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { AuthService } from 'ngx-auth';
import { deleteDB } from 'idb';

import { UserService } from '../_services/user.service';
import { MessagingService } from '../_services/messaging.service';
import { AccessData } from './access-data';
import { Credential } from './credential';

import { GlobalService } from '../_services/global.service';
import { CookieService } from 'ngx-cookie-service';
import { ResponseApiModel } from '../_models/response-api.model';
import { NgxPermissionsService } from 'ngx-permissions';

const API_ENDPOINT_LOGIN = '/auth/login';
const API_ENDPOINT_LOGIN_SSO = '/auth/login-sso';
const API_ENDPOINT_FORGOT = '/auth/forgot-password';
const API_ENDPOINT_RESET = '/auth/reset-password';
const API_ENDPOINT_RELOAD_IDB = '/auth/reload-indexed-db';
const API_ENDPOINT_APPLY_VACANCY = '/auth/apply-vacancy';
const API_ENDPOINT_REFRESH = '/refresh';
const API_ENDPOINT_REGISTER = '/register';
const API_ENDPOINT_GET_ROLES = '/auth/get-permissions';
const API_ENDPOINT_CHECK_PASSWORD_EXP = '/auth/check-password-exp';
const API_ENDPOINT_CHECK_TOKEN = '/auth/check-token-reset-password';

@Injectable()
export class AuthenticationService implements AuthService {

  public onCredentialUpdated$: Subject<ResponseApiModel>;
  public currentLat: any;
  public currentLong: any;
  public returnUrl: string;

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private messagingService: MessagingService,
    private globalService: GlobalService,
    private cookieService: CookieService,
    private _router: Router,
    private permissionsService: NgxPermissionsService,
    private route: ActivatedRoute,
  ) {
    this.onCredentialUpdated$ = new Subject();
  }

  public header() {
    return { headers: new HttpHeaders().set('token', this.cookieService.get('_q')) };
  }

  /**
   * Check, if user already authorized.
   * @description Should return Observable with true or false values
   * @returns {Observable<boolean>}
   * @memberOf AuthService
   */
  public isAuthorized(): Observable<boolean> {
    return this.userService.getAccessToken().pipe(map(token => !!token));
  }

  /**
   * Get access token
   * @description Should return access token in Observable from e.g. localStorage
   * @returns {Observable<string>}
   */
  public getAccessToken(): Observable<string> {
    return this.userService.getAccessToken();
  }

  /**
 * Get access token
 * @description Should return access token in Observable from e.g. localStorage
 * @returns {Observable<string>}
 */
  public getUserRoles(module: string): any {
    return this.userService.getUserRoles(module);
  }

  public deleteUserRoles(module: string): any {
    localStorage.removeItem(module);
    return module;
  }

  /**
   * Get user roles
   * @returns {Observable<any>}
   */
  public getRolesToken(params: { module: string }): Observable<any> {
    return this.http.post<ResponseApiModel>(this.globalService.apiVersionHost + API_ENDPOINT_GET_ROLES, params, this.header())
      .pipe(
        tap(this.saveRolesToken.bind(this))
      );
  }

  /**
   * Function, that should perform refresh token verifyTokenRequest
   * @description Should be successfully completed so interceptor
   * can execute pending requests or retry original one
   * @returns {Observable<any>}
   */
  public refreshToken(): Observable<AccessData> {
    return this.userService.getRefreshToken().pipe(
      switchMap((refreshToken: string) => {
        return this.http.post<AccessData>(this.globalService.apiVersionHost + API_ENDPOINT_REFRESH, refreshToken);
      }),
      tap(this.saveAccessData.bind(this)),
      catchError(err => {
        this.logout();
        return throwError(err);
      })
    );
  }

  /**
   * Can't be delete from script
   */
  public refreshShouldHappen(response: HttpErrorResponse): boolean {
    return response.status === 401;
  }

  /**
   * Verify that outgoing request is refresh-token,
   * so interceptor won't intercept this request
   * @param {string} url
   * @returns {boolean}
   */
  public verifyTokenRequest(url: string): boolean {
    return url.endsWith(API_ENDPOINT_REFRESH);
  }

  /**
     * Add token to headers, dependent on server
     * set-up, by default adds a bearer token.
     * Called by interceptor.
     *
     * To change behavior, override this method.
     */
  // public getHeaders(token: string) {
  //   return {tokenx : token};
  // }

  /**
   * Submit login request
   * @param {Credential} credential
   * @returns {Observable<any>}
   */
  public login(credential: Credential) {
    return this.http.post<ResponseApiModel>(this.globalService.apiVersionLogin + API_ENDPOINT_LOGIN, credential)
      .pipe(
        tap(this.saveAccessData.bind(this))
      );
  }

  public loginSso(credential: Credential) {
    return this.http.post<ResponseApiModel>(this.globalService.apiVersionLogin + API_ENDPOINT_LOGIN_SSO, credential)
      .pipe(
        tap(this.saveAccessData.bind(this))
      );
  }

  /**
 * Submit forgot request
 * @param {Credential} credential
 * @returns {Observable<any>}
 */
  public forgot(credential: Credential) {
    return this.http.post<ResponseApiModel>(this.globalService.apiVersionHost + API_ENDPOINT_FORGOT, credential)
      .pipe(map(response => response));
  }

  /**
 * Submit reset password
 * @param {Credential} credential
 * @returns {Observable<any>}
 */
  public resetPassword(param: { password: string, token: string }) {
    return this.http.post<ResponseApiModel>(this.globalService.apiVersionHost + API_ENDPOINT_RESET, param)
      .pipe(map(response => response));
  }

  public checkTokenResetPassword(param: { token: string }) {
    return this.http.post<ResponseApiModel>(this.globalService.apiVersionHost + API_ENDPOINT_CHECK_TOKEN, param)
      .pipe(map(response => response));
  }
  /**
 * Reload Fcm Token
 * @param {Credential} credential
 * @returns {Observable<any>}
 */
  public reloadIdb(param: { username: string, fcm_token: string, old_token: string }) {
    return this.http.post<ResponseApiModel>(this.globalService.apiVersionHost + API_ENDPOINT_RELOAD_IDB, param)
      .pipe(map(response => response));
  }

  /**
 * Submit login request and apply vacancy
 * @param {Credential} credential
 * @returns {Observable<any>}
 */
  public applyVacancy(credential: any) {
    return this.http.post<ResponseApiModel>(this.globalService.apiVersionHost + API_ENDPOINT_APPLY_VACANCY, credential)
      .pipe(
        tap(this.saveVacancyAccessData.bind(this))
      );
  }

  /**
 * Logout
 */
  public logout(dari?: string): void {
    if (/ptnw\.net/.test(window.location.hostname)) {
      this.cookieService.deleteAll('/');
      this.cookieService.deleteAll('/', '.ptnw.net');
    } else if (/nexwaveindonesia\.com/.test(window.location.hostname)) {
      this.cookieService.deleteAll('/');
      this.cookieService.deleteAll('/', '.nexwaveindonesia.com');
    } else {
      this.cookieService.deleteAll('/');
    }
    
    this.userService.setFcmToken(this.messagingService.currentToken); // Except Fcm Token

    let intro = localStorage.getItem('intro-my-approval');
    localStorage.clear(); // Clear Local Storage
    if (intro) {
      localStorage.setItem('intro-my-approval', 'true');
    }

    deleteDB('DatabaseNds'); // Clear IndexedDB
    if (dari === 'login') {
      this._router.navigate(['/login']);
    } else {
      this._router.navigate(['/login']);
    }
  }

  /**
 * Save access data in the storage
 * @private
 * @param {AccessData} data
 */
  private saveAccessData(response: ResponseApiModel) {
    if (response.status) {
      this.userService
        .setAccessToken(response.result._q)
        .setRefreshToken(response.result._w)
        .setGroupToken(response.result._g);
      this.onCredentialUpdated$.next(response);
    }
  }

  /**
 * Save access data in the storage
 * @private
 * @param {AccessData} data
 */
  private saveVacancyAccessData(response: ResponseApiModel) {
    if (response.status) {
      this.userService
        .setAccessToken(response.result._q)
        .setRefreshToken(response.result._w)
        .setGroupToken(response.result._g)
        .setVacancyToken(response.result._x);
      this.onCredentialUpdated$.next(response);
    }
  }

  /**
 * Save roles data in the storage
 * @private
 * @param {AccessData} data
 */
  private saveRolesToken(response: ResponseApiModel) {
    if (response.status) {
      this.userService
        .setRolesToken(response.result.token, response.result.module);
    }
  }

  /**
   * Submit registration request
   * @param {Credential} credential
   * @returns {Observable<any>}
   */
  public register(credential: Credential) {
    // dummy token creation
    credential = Object.assign({}, credential, {
      accessToken: 'access-token-' + Math.random(),
      refreshToken: 'access-token-' + Math.random(),
      roles: ['USER'],
    });
    return this.http.post(this.globalService.apiVersionHost + API_ENDPOINT_REGISTER, credential)
      .pipe();
  }

  /**
   * Submit forgot password request
   * @param {Credential} credential
   * @returns {Observable<any>}
   */
  public requestPassword(credential: Credential) {
    return this.http.post(this.globalService.apiVersionHost + API_ENDPOINT_LOGIN, credential)
      .pipe();
  }

  public getPermission(module: string) {
    let roles = this.getUserRoles(module);
    if (roles) {
      this.loadPermission(roles);
    }
    else {
      this.getRolesToken({ module: module }).subscribe(
        response => {
          if (response.status) {
            let roles = this.getUserRoles(module);
            if (roles) {
              this.loadPermission(roles);
            }
          }
        }
      );
    }
  }

  public loadPermission(permissions) {
    this.permissionsService.addPermission(permissions);
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    if (this.returnUrl != '/') {
      this._router.navigateByUrl(this.returnUrl);
    }
  }

  public reloadAuthorization(module: string): Observable<ResponseApiModel> {

    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + '/auth/get-permissions',
        { module: module },
        this.header()
      )
      .pipe(map(response => response));
  }

  public checkPasswordExp(): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_ENDPOINT_CHECK_PASSWORD_EXP, null, this.header()
      )
      .pipe(map(response => response));
  }

  public createSso(): Observable<ResponseApiModel> {

    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + '/auth/create-sso',
        null,
        this.header()
      )
      .pipe(map(response => response));
  }

  public tokenExpired() {
    this.userService.getAccessToken().subscribe(token => {
      if (token) {
        const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
        return (Math.floor((new Date).getTime() / 1000)) >= expiry;
      } else {
        return false;
      }
    });
  }

}
