import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable()
export class GlobalService {

    public webHost: string;
    public apiHost: string;
    public apiVersionLogin: string;
    public apiVersion: string;
    public apiVersionHost: string;
    public firebaseHost: string;

    public setting: any = {};

    constructor() {
        if (environment.production) {
            this.webHost = environment.webhost;
            this.apiHost = environment.host;
            this.apiVersionLogin = 'https://api.ptnw.net'+ '/' + this.apiVersion;;
            this.apiVersion = environment.apiVersion;
            this.apiVersionHost = this.apiHost + '/' + this.apiVersion;
            this.firebaseHost = environment.firebaseHost;
        } else {
            this.webHost = environment.webhost;
            this.apiHost = environment.host;
            this.apiVersionLogin =  this.apiHost + '/' + this.apiVersion;
            this.apiVersion = environment.apiVersion;
            this.apiVersionHost = this.apiHost + '/' + this.apiVersion;
            this.firebaseHost = environment.firebaseHost;
        }
    }

    loadGlobalSettingsFromLocalStorage(): void {
        if (localStorage.getItem('access-setting') != null) {
            this.setting = JSON.parse(localStorage.getItem('access-setting'));
        }
    }

}
